import { createContext, useContext, useState, useEffect } from "react";

import ErrorContext from "./ErrorContext";
import AuthContext from "./AuthContext";

const LicensingContext = createContext();

export const LicensingProvider = ({ children }) => {
  
  const [list, setList] = useState([]);

  const { setHasError, setErrorObj } = useContext(ErrorContext);
  const { isLogged, user } = useContext(AuthContext);

  const populateList = (data) => {
    if (Array.isArray(data)) {
      const items = data.map((item) => { return { company: item.company, email: item.email, licenseKey: item.sl, expiration: item.expiration, identity: item.identity }; })
      return setList(items);
    }
    return setList([]);
  }

  useEffect(() => {
    if (isLogged) {
      const { role } = user;
      if(role === 'user' || role === 'staff') {
        const { email } = user;
        fetch(`/api/v1/licensing/clients?email=${email}`)
        .then((res) => res.json())
        .then((data) => {
          return populateList(data);
        })
        .catch((err) => {
          setHasError(true);
          return setErrorObj({ errors: [{ message: err.message }] });
        });
      }
      else if(role === 'rep') {
        const { company } = user;
        fetch(`/api/v1/licensing/clients?company=${company}`)
        .then((res) => res.json())
        .then((data) => {
          return populateList(data);
        })
        .catch((err) => {
          setHasError(true);
          return setErrorObj({ errors: [{ message: err.message }] });
        });
      }
      else if(role === 'admin') {
        fetch(`/api/v1/licensing/clients`)
        .then((res) => res.json())
        .then((data) => {
          return populateList(data);
        })
        .catch((err) => {
          setHasError(true);
          return setErrorObj({ errors: [{ message: err.message }] });
        });
      }
    }
  }, [isLogged]);

  return (
    <LicensingContext.Provider value={{ list }}>
      {children}
    </LicensingContext.Provider>
  );
};

export default LicensingContext;
