import { useContext } from "react";

import LicensingContext from "../contexts/LicensingContext";

import Table from "../components/Table";

const List = () => {
  const { list } = useContext(LicensingContext);
  return (<Table items={list} />);
}
export default List;