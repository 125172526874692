import { useContext } from "react";
import { Link } from 'react-router-dom'

import AuthContext from "../contexts/AuthContext";

import { Button } from "../components/Button";

import { PasswordChange } from "./PasswordChange";

const Navbar = () => {
    const { signOut } = useContext(AuthContext);

    return (
        <nav className="flex items-center justify-between bg-gray-800 h-20 shadow-2xl">
        <div className="logo">
          <h1 className="text-white ml-4 cursor-pointer text-2xl">HTRI Cloud Licensing</h1>
        </div>
        <ul className="flex gap-3">
          <li key="password">
            <PasswordChange />
          </li>
          <li key="logout">
            <Button type="button" onClick={() => setIsOpen(true)}>
              <Link onClick={signOut} to="/">
                Log Out
              </Link>
            </Button>
          </li>
        </ul>
      </nav>
    );
}

export default Navbar;