import { createRoot } from "react-dom/client"
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import App from "./App"

import { ErrorProvider } from './contexts/ErrorContext'
import { AuthProvider } from './contexts/AuthContext'
import { LicensingProvider } from './contexts/LicensingContext'

const container = document.getElementById("root");

const root = createRoot(container);

root.render(
  <ErrorProvider>
    <AuthProvider>
      <LicensingProvider>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<App />} />
          </Routes>
        </BrowserRouter>
      </LicensingProvider>
    </AuthProvider>
</ErrorProvider>
);
