import { Button } from "../components/Button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "../components/Dialog";
import { Field, Label } from "../components/Fieldset";
import { Input } from "../components/Input";
import { useContext, useState } from "react";

import AuthContext from "../contexts/AuthContext";

export function PasswordChange() {

  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const { user, changePassword } = useContext(AuthContext);

  const onCurrentChange = (e) => {
    setCurrent(e.target.value);
  }

  const onNewPasswordChange = (e) => {
    if(confirmation === e.target.value) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    setNewPassword(e.target.value);
  }

  const onConfirmationChange = (e) => {
    if(newPassword === e.target.value) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    setConfirmation(e.target.value);
  }

  const onPasswordChangeClick = () => {
    if(isDisabled) return;
    setIsOpen(false);
    changePassword({ email: user.email, password: current, newPassword: newPassword });
  }

  return (
    <>
      <Button type="button" onClick={() => setIsOpen(true)}>
        Change Password
      </Button>
      <Dialog open={isOpen} onClose={setIsOpen}>
        <DialogTitle>Password Change for {user.email}</DialogTitle>
        <DialogDescription>
          Password must be 8 - 48 characters long
        </DialogDescription>
        <DialogBody>
          <Field>
            <Label>Current Password</Label>
            <Input name="current" type="password" value={current} onChange={(e) => onCurrentChange(e)} />
          </Field>
          <Field>
            <Label>New Password</Label>
            <Input name="new" type="password" value={newPassword} onChange={(e) => onNewPasswordChange(e)} />
          </Field>
          <Field>
            <Label>Password Confirmation</Label>
            <Input name="confirm" type="password" value={confirmation} onChange={(e) => onConfirmationChange(e)} />
            {isDisabled && <label className="text-yellow-500">Password must match</label>}
          </Field>
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={() => onPasswordChangeClick()}>Change</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
