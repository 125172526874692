import { useContext } from "react";

import ErrorContext from "./contexts/ErrorContext";
import AuthContext from "./contexts/AuthContext";

import Error from "./components/Error";

import Auth from "./pages/Auth";
import Navbar from "./pages/Navbar";
import List from "./pages/List";

export default function App() {
  const { hasError } = useContext(ErrorContext);
  const { isLogged } = useContext(AuthContext);

  return isLogged ? (
    <div className="font-sans text-lg">
      {hasError && <Error />}
      <Navbar />
      <List />
    </div>
  ) : (
    <div>
      {hasError && <Error />}
      <Auth />
    </div>
  );
}
