import { createContext, useState, useRef, useCallback } from "react";

const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const errorObj = useRef({});
  const resetError = useCallback(() => {
    errorObj.current = {};
    return setHasError(false);
  }, []);
  const setError = useCallback((err) => {
    errorObj.current = { errors: [{ message: err.message }] };
    return setHasError(true);
  }, []);
  return (
    <ErrorContext.Provider
      value={{ hasError, errorObj: errorObj.current, resetError, setError }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

export default ErrorContext;
