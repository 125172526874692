import { useContext } from "react";

import ErrorContext from "../contexts/ErrorContext";

import { XCircleIcon } from "@heroicons/react/20/solid";

const Error = () => {
  const { errorObj } = useContext(ErrorContext);

  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            {`${errorObj.errors.length} found:`}
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul role="list" className="list-disc space-y-1 pl-5">
              {errorObj.errors.map((item) =>
                Object.entries(item).map(([key, value]) => (
                  <li key={key}>
                    <div className="">
                      <strong>Error: </strong>
                      {value}
                    </div>
                  </li>
                )),
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
