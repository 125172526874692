import { useContext, useState, useEffect } from "react";

import logo from "../images/logo.svg";
import landing from "../images/landing.jpg";

import ErrorContext from "../contexts/ErrorContext";
import AuthContext from "../contexts/AuthContext";

export default function Auth() {
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { resetError } = useContext(ErrorContext);
  const { signIn } = useContext(AuthContext);

  const onEmailChangeHandler = (e) => {
    e.preventDefault();
    resetError();
    setEmail(e.target.value);
  };

  const onPasswordChangeHandler = (e) => {
    e.preventDefault();
    resetError();
    setPassword(e.target.value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const p = password;
    setPassword("");
    if (checked) {
      localStorage.setItem("email", JSON.stringify(email));
    }
    return signIn({ email, password: p });
  };

  const checkboxOnChangeHandler = (e) => {
    if (checked) {
      localStorage.removeItem("email");
    }
    setChecked(!checked);
  };

  useEffect(() => {
    const email = JSON.parse(localStorage.getItem("email"));
    if (email) {
      setChecked(true);
      setEmail(email);
    }
  }, []);

  return (
    <div className="flex min-h-full flex-1">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-20 w-auto" src={logo} alt="HTRI" />
            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Cloud Licensing Services
            </h2>
            <p className="mt-2 text-sm leading-6 text-gray-500">
              <span className="mr-1">Sign in to your account</span>
            </p>
          </div>
          <div className="mt-10">
            <div>
              <form
                action="#"
                method="POST"
                className="space-y-6"
                onSubmit={onSubmitHandler}
              >
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      onChange={onEmailChangeHandler}
                      required
                      value={email}
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      onChange={onPasswordChangeHandler}
                      required
                      value={password}
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                      checked={checked}
                      onChange={checkboxOnChangeHandler}
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-3 block text-sm leading-6 text-gray-700"
                    >
                      Remember me
                    </label>
                  </div>
                  <div className="text-sm leading-6"></div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-sky-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full rounded-lg object-cover p-1 shadow-lg"
          src={landing}
          alt="HTRI"
        />
      </div>
    </div>
  );
}
