import { createContext, useContext, useState, useRef, useCallback, useEffect } from 'react'

import ErrorContext from './ErrorContext'

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { setError } = useContext(ErrorContext);
  const [isLogged, setIsLogged] = useState(false);
  const user = useRef({});

  const authenticate = (resBody) => {
    const { currentUser } = resBody;
    if (!currentUser) {
      user.current = {};
      return setIsLogged(false);
    }
    user.current = currentUser;
    return setIsLogged(true);
  };

  useEffect(() => {
    fetch(`/api/v1/users/current-user`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw Error('Unable to connect to authentication server');
        }
        return res.json();
      })
      .then((resBody) => authenticate(resBody))
      .catch((err) => setError(err));
  }, [setError]);

  const signIn = useCallback((credentials) => {
    fetch(`/api/v1/users/signin`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    })
      .then((res) => {
        if (res.status !== 201) {
          throw Error('Invalid Credentials');
        }
        return res.json();
      })
      .then((resBody) => authenticate(resBody))
      .catch((err) => setError(err));
  }, []);

  const signOut = useCallback(() => {
    fetch(`/api/v1/users/signout`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: {},
    })
      .then((res) => authenticate({}))
      .catch((err) => setError(err));
  }, []);

  const changePassword = useCallback((credentials) => {
    fetch(`/api/v1/users/update`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    })
      .then((res) => {
        if (res.status !== 200) {
          throw Error('Invalid Credentials');
        }
      })
      .catch((err) => setError(err));
  }, []);

  return (
    <AuthContext.Provider value={{ user: user.current, isLogged, signIn, signOut, changePassword }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
